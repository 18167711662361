import React from "react";
import ADBLogo from "../../Assets/logo/ADBLogo";
import { navigate } from "@reach/router";
import TabButton from "../TabButton";
import NotificationsIcon from "./NotificationsIcon";
import GearIcon from "./GearIcon";
import AccountIcon from "./AccountIcon";
import AccountButton from "./AccountButton";
import AccountMenu from "./AccountMenu";

const Header: React.FC = () => {
  const onHomeClick = () => {
    navigate(`/relationships`);
  };

  return (
    <div
      style={{ minHeight: "8rem" }}
      className="flex text-xl text-black h-32 w-full bg-sky text-deepSea font-bold justify-center"
    >
      <div className="flex w-2/3 justify-between items-end ml-2 md:ml-0">
        <div className="flex items-end">
          <button onClick={onHomeClick} className="mb-5 focus:outline-none">
            <ADBLogo />
          </button>
        </div>
        <div className="h-full flex flex-col items-end justify-between">
          <div className="flex w-32 justify-end mt-6">
            <AccountMenu />
          </div>
          <div className="flex justify-between items-end">
            <TabButton
              inactiveContent="Entities"
              activeContent="Entities"
              path="/entities"
              pathPrefix="/entities"
              className="px-8"
            />
            <TabButton
              inactiveContent="Relationships"
              activeContent="Relationships"
              path="/relationships"
              pathPrefix="/relationships"
              className="px-8"
            />
            {/*<TabButton*/}
            {/*  inactiveContent="Registers"*/}
            {/*  activeContent="Registers"*/}
            {/*  path="/registers"*/}
            {/*  pathPrefix="/registers"*/}
            {/*  className="px-8"*/}
            {/*/>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
