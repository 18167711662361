import { AppStore } from "../../rootReducer";
import { Maybe } from "../../types";

export const getPersonByUuid = (state: AppStore, uuid: Maybe<string>) => {
  if (!uuid) {
    return null;
  }
  return state.personStore.persons[uuid];
};

export const getAllPersons = (state: AppStore) => {
  return state.personStore.persons;
};
