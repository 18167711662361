import React from "react";
import { navigate, useLocation } from "@reach/router";

import classNames from "classnames";
import Tick from "../Assets/Tick";
import { NewRelationshipPage } from "../Relationship/types";
import { pathnameToStepMap, stepToPathnameMap } from "../utils";
import { useGetActiveRelationship } from "../Relationship/relationshipHooks";
import { Maybe } from "../../types";

interface Props {
  text: string;
  stepName: NewRelationshipPage;
}

const isPathActive = (pathName: string, stepName: string, relationshipUuid: Maybe<string>): boolean => {
  if (!relationshipUuid && stepName === "senderDetails") {
    return true;
  }
  if (!relationshipUuid) {
    return false;
  }
  return pathnameToStepMap(relationshipUuid)[pathName] === stepName;
};

const Step = ({ text, stepName }: Props) => {
  const location = useLocation();

  const { pathname } = location;

  const activeRelationship = useGetActiveRelationship();

  const isActive = isPathActive(pathname, stepName, activeRelationship?.relationshipUuid);

  const handleClick = () => {
    if (activeRelationship) {
      navigate(stepToPathnameMap(activeRelationship.relationshipUuid)[stepName]);
    } else {
      if (stepName !== "senderDetails") {
        alert("Please add sender details first");
      }
    }
  };

  return (
    <div
      onClick={handleClick}
      className={classNames("group w-64 flex flex-row items-center h-9 text-black cursor-pointer", {
        "bg-sky hover:skyDarker": isActive,
        "bg-white hover:bg-cloud": !isActive,
      })}
    >
      <div className={classNames("w-1 bg-pacificBlue h-full", { invisible: !isActive })} />
      <div className="w-4" />
      <div className="mr-2">
        <Tick isActive={isActive} />
      </div>
      <div className={classNames({ "font-bold": isActive })}>{text}</div>
    </div>
  );
};

export default Step;
