import React from "react";

const MonitorContact = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.07687 1.07693C3.07687 0.830772 2.86148 0.615387 2.61533 0.615387H1.69225C1.4461 0.615387 1.23071 0.830772 1.23071 1.07693V14.9231C1.23071 15.1692 1.4461 15.3846 1.69225 15.3846H2.61533C2.86148 15.3846 3.07687 15.1692 3.07687 14.9231V1.07693ZM14.923 2.36923C9.99994 4.95385 10.5538 -0.338459 4.61533 1.72308C4.43071 1.78462 4.30764 1.96923 4.30764 2.15385V9.32308C4.30764 9.53846 4.52302 9.69231 4.70764 9.6C10.6153 7.63077 9.99994 13.0462 15.1384 10.1538C15.2923 10.0615 15.3846 9.90769 15.3846 9.75385V2.61539C15.3846 2.4 15.1384 2.24616 14.923 2.36923Z"
        fill="white"
      />
    </svg>
  );
};

export default MonitorContact;
