import React from 'react';
import ReactLoading from 'react-loading';

interface Props {
  className?: string;
  strokeWidth?: number;
}

const LoadingSpinner: React.FC<Props> = ({ className = "h-4 w-4", strokeWidth = 2 }) => {
  return (
      <ReactLoading type={"bars"} color={"#0070D2"} height={50} width={50} />
  );
};

export default LoadingSpinner;
