import React from "react";
import { startCase } from "lodash";
// @ts-ignore
import Flags from "country-flag-icons/react/3x2";
import { Relationship } from "../Relationship/types";
import { usePerson } from "../Person/personHooks";
import { useGoToStep } from "../Relationship/relationshipHooks";
import moment from "moment";
import Checkbox from "../Search/Checkbox";
import classNames from "classnames";
import Watch from "../Assets/Watch";
import { navigate } from "@reach/router";

interface Props {
  relationship: Relationship;
}

const RelationshipRow = ({ relationship }: Props) => {
  const getPerson = usePerson();
  const sender = getPerson(relationship?.senderUuid);
  const receiver = getPerson(relationship?.receiverUuid);

  const goToStep = useGoToStep();

  const handleRowClick = () => {
    goToStep(relationship.relationshipUuid);
  };

  const handleViewClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    navigate(`relationships/${relationship.relationshipUuid}/view`);
  };

  const [checked, setChecked] = React.useState(false);
  return (
    <tr className="h-10 border-b text-xs font-thin bg-white hover:bg-cotton cursor-pointer" onClick={handleRowClick}>
      <td className="pl-4">
        <Checkbox checked={checked} onClick={() => setChecked(!checked)} />
      </td>
      <td>
        <div className="flex px-2 text-pacificBlue underline">
          <div className="px-1">
            {sender?.firstName} {sender?.lastName}
          </div>
        </div>
      </td>
      <td className="pl-4">
        <div className="flex text-pacificBlue underline">
          <div className="px-1">
            {receiver?.firstName} {receiver?.lastName}
          </div>
        </div>
      </td>
      <td>{moment(relationship.createdAt).format("DD MMM, YYYY")}</td>
      <td>
        <div className="flex flex-row items-center">
          <div
            className={classNames("w-3 h-3 mr-1", {
              "bg-merlot": relationship.status === "rejected",
              "bg-ocean": relationship.status === "draft",
              "bg-daffodil": relationship.status === "received",
              "bg-waipoua": relationship.status === "approved",
            })}
          />
          <div>{startCase(relationship.status)}</div>
        </div>
      </td>
      <td>
        {relationship?.status === "Active" ? (
          <div onClick={(e) => handleViewClick(e)}>
            <Watch />
          </div>
        ) : null}
      </td>
    </tr>
  );
};

export default RelationshipRow;
