import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import { useUpdateActiveRelationship } from "./relationshipHooks";
import Plus from "../Assets/Plus";
import CustomButton from "../Viewer/CustomButton";
import RelationshipTable from "./RelationshipTable";
import FilterButton from "../Dashboard/FilterButton";
import QuickFilter from "../../utils/QuickFilter";
import { Nullable } from "../../types";

const RelationshipsPage: React.FC<RouteComponentProps> = () => {
  const updateActiveRelationship = useUpdateActiveRelationship();
  const [searchValue, setSearchValue] = React.useState<Nullable<string>>(null);
  const [filter, setFilter] = React.useState<"all" | "draft" | "received" | "rejected">("all");

  const goToNewRelationshipForm = () => {
    updateActiveRelationship(null);
    navigate("/relationships/new/sender-details");
  };

  return (
    <div className="flex flex-1">
      <div className="w-full flex justify-center py-4 min-w-64">
        <div className="flex flex-col flex-1">
          <div className="flex justify-between mb-3">
            <div className="flex bg-white justify-center pl-0 p-4 font-bold text-xl">Search Relationships</div>
            <div className="flex justify-center items-center">
              <CustomButton onClick={goToNewRelationshipForm} theme={"primary"} disablePadding className="py-4 pl-0">
                <div className="flex">
                  <div className="h-full m-2">
                    <Plus />
                  </div>
                  <div className="flex items-center text-sm font-thin w-32">New Relationship</div>
                </div>
              </CustomButton>
            </div>
          </div>
          <div className="flex justify-between mb-2">
            <div className="flex items-center">
              <FilterButton text="All" active={filter === "all"} onClick={() => setFilter("all")} />
              <FilterButton text="Draft" active={filter === "draft"} onClick={() => setFilter("draft")} />
              <FilterButton text="Received" active={filter === "received"} onClick={() => setFilter("received")} />
              <FilterButton text="Rejected" active={filter === "rejected"} onClick={() => setFilter("rejected")} />
            </div>
            <QuickFilter onChange={(e) => setSearchValue(e.target.value)} className="w-64" placeholder="Quick find" />
          </div>
          <RelationshipTable statusFilter={filter} searchValue={searchValue} />
        </div>
      </div>
    </div>
  );
};

export default RelationshipsPage;
