import React from "react";
import { RouteComponentProps } from "@reach/router";

import { Nullable } from "../../types";
import QuickFilter from "../../utils/QuickFilter";
import CustomButton from "../Viewer/CustomButton";
import Plus from "../Assets/Plus";
import EntityTable from "./EntityTable";

const EntitiesPage: React.FC<RouteComponentProps> = () => {
  const [searchValue, setSearchValue] = React.useState<Nullable<string>>(null);

  return (
    <div className="flex flex-1">
      <div className="w-full flex justify-center py-4 min-w-64">
        <div className="flex flex-col flex-1">
          <div className="flex justify-between mb-3">
            <div className="flex bg-white justify-center pl-0 p-4 font-bold text-xl">Search Customers</div>
            <div className="flex justify-center items-center">
              <CustomButton
                onClick={() => alert("Page not built yet")}
                theme={"primary"}
                disablePadding
                className="py-4 pl-0"
              >
                <div className="flex">
                  <div className="h-full m-2">
                    <Plus />
                  </div>
                  <div className="flex items-center text-sm font-thin w-28">New Customer</div>
                </div>
              </CustomButton>
            </div>
          </div>
          <div className="mb-6">
            <QuickFilter onChange={(e) => setSearchValue(e.target.value)} className="w-64" placeholder="Quick find" />
          </div>
          <EntityTable searchValue={searchValue} />
        </div>
      </div>
    </div>
  );
};

export default EntitiesPage;
