import React from "react";

import TICCCompanyLogo from "../Assets/logo/TICCCompanyLogo";
import { useSelector } from "react-redux";
import { getAccountUser } from "../Account/accountSelectors";

const Footer: React.FC = () => {
  const user = useSelector(getAccountUser);
  return (
    <div
      style={{ minHeight: "10rem" }}
      className="bg-foundation flex flex-col h-40 justify-center items-center text-xs"
    >
      <div>Powered By</div>
      <TICCCompanyLogo />
      {user?.bankName && <div className="pt-1">{`Logged in as: ${user.bankName}`}</div>}
      <div className="mt-3">
        <a href="https://www.ticc.nz/privacy-and-data-policies" target="_blank" rel="noopener">
          Privacy
        </a>{" "}
        |{" "}
        <a href="https://status.salesforce.com/" target="_blank" rel="noopener">
          System Status
        </a>
      </div>
    </div>
  );
};

export default Footer;
