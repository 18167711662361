import React from "react";
import CertifiedTick from "./CertifiedTick";
import { Person } from "../Person/types";

interface Props {
  person: Person;
  type: string;
}

const CertifiedPerson = ({ person, type }: Props) => {
  return (
    <div className="flex">
      <div className="w-16 h-16 rounded-full flex justify-center items-center border-2 border-deepSea mr-3">
        {person?.firstName?.charAt(0)}
        {person?.lastName?.charAt(0)}
      </div>
      <div>
        <div className="flex">
          <div className="text-sm mr-4">{type.toUpperCase()}</div>
          <div className="text-sm" />
        </div>
        <div className="text-2xl">
          {person?.firstName} {person?.lastName}
        </div>
        <div className="text-sm">{person?.citizenship}</div>
      </div>
      <div className="flex justify-center items-center ml-4">
        <CertifiedTick />
      </div>
    </div>
  );
};

export default CertifiedPerson;
