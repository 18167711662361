import React from "react";

const ContactHistoryIcon = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.09997 3.98462C12.3 3.98462 14.9154 6.56923 14.9154 9.8C14.9154 13.0308 12.3 15.6154 9.09997 15.6154H6.57689C6.33074 15.6154 6.17689 15.4308 6.17689 15.1846V14.2C6.17689 13.9538 6.36151 13.7385 6.60766 13.7385H9.09997C11.2846 13.7385 13.0384 11.9846 13.0384 9.8C13.0384 7.61539 11.2846 5.86154 9.09997 5.86154H4.82305C4.82305 5.86154 4.57689 5.86154 4.48459 5.89231C4.23843 6.01539 4.29997 6.2 4.51536 6.41539L6.02305 7.92308C6.20766 8.10769 6.17689 8.38462 5.99228 8.56923L5.31536 9.24616C5.13074 9.43077 4.91536 9.43077 4.73074 9.27693L0.730741 5.27693C0.576895 5.12308 0.576895 4.87693 0.730741 4.72308L4.69997 0.753849C4.88459 0.569233 5.19228 0.569233 5.34613 0.753849L5.99228 1.4C6.17689 1.58462 6.17689 1.89231 5.99228 2.04616L4.48459 3.55385C4.29997 3.73846 4.29997 3.95385 4.60766 3.95385H4.82305L9.09997 3.98462V3.98462Z"
        fill="white"
      />
    </svg>
  );
};

export default ContactHistoryIcon;
