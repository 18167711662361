import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Person, PersonStore } from "./types";

const initialState: PersonStore = {
  persons: {},
};

const personSlice = createSlice({
  name: "personStore",
  initialState,
  reducers: {
    updatePerson: (state, action: PayloadAction<Person>) => {
      state.persons = {
        ...state.persons,
        [action.payload.uuid]: { ...state.persons[action.payload.uuid], ...action.payload },
      };
    },
    addEntities: (state, action: PayloadAction<{ [uuid: string]: Person }>) => {
      state.persons = {
        ...state.persons,
        ...action.payload,
      };
    },
  },
});

export const { updatePerson, addEntities } = personSlice.actions;

export default personSlice.reducer;
