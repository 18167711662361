import React from "react";
import moment from "moment";

import { Api } from "../API/API";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addEntities } from "../Person/personSlice";
import { CustomerApi } from "../API/types";
import { replaceWhitespace } from "../utils";
import { BankNotSupportedMessage } from "../constants";
import { getAccountUser, getIsLoadingAccountUser } from "../Account/accountSelectors";

export const shapeEntity = (entity: CustomerApi, uuid: string) => ({
  uuid,
  firstName: entity.firstName,
  middleName: "",
  lastName: entity.lastName,
  dob: moment(entity.dateOfBirth).valueOf(),
  city: entity.cityOfBirth,
  citizenship: entity.citizenship,
  otherNames: "",
  idDocumentNumber: entity.idDocumentNumber,
  idDocumentType: entity.idDocumentType,
  mobile: entity.mobilePhone,
  email: entity.email,
  IRDNumber: entity.irdNumber1,
  samoanIRDNumber: entity.irdNumber1,
  employer: entity.employer,
  nearestBranch: "",
  streetAddress: entity.streetAddress,
  cityAddress: entity.cityAddress,
  postalCodeAddress: entity.postalCodeAddress,
  countryAddress: entity.countryAddress,
});

export const useGetCustomers = () => {
  const [loading, setLoading] = React.useState(false);
  const [cancelled, setCancelled] = React.useState(false);
  const dispatch = useAppDispatch();

  const accountUser = useAppSelector(getAccountUser);
  const isLoadingAccountUser = useAppSelector(getIsLoadingAccountUser);

  const getCustomers = async () => {
    if (isLoadingAccountUser) {
      return;
    }
    if (!accountUser?.bankName) {
      alert(BankNotSupportedMessage);
      return;
    }
    setLoading(true);
    try {
      const searchFields = {
        searchKey: "example",
        offset: 0,
        limitRecords: "50",
        searchBy: "name",
        bankName: accountUser.bankName,
      };
      const customers = await Api.getCustomers(searchFields);
      if (cancelled) {
        return;
      }
      const shapedData = customers.reduce((accum, customer) => {
        const uuid = customer.uuid
          ? customer.uuid
          : replaceWhitespace(
              `${customer.firstName}_${customer.lastName}_${customer.dateOfBirth}_${customer.cityOfBirth}`,
              "_",
            );

        return { ...accum, [uuid]: shapeEntity(customer, uuid) };
      }, {});
      dispatch(addEntities(shapedData));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getCustomers();
    return () => {
      setCancelled(true);
    };
  }, [dispatch, setLoading]);

  return {
    loading,
  };
};
