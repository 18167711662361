import React from "react";
import classNames from "classnames";

interface Props {
  text: string;
  onClick: () => void;
  active?: boolean;
}

const FilterButton = ({ text, onClick, active = false }: Props) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "border rounded-full py-1 px-4 mr-2 text-sm focus:outline-none font-light",
        {
          "bg-pacificBlue text-white hover:bg-pacificBlueDeep": active,
          "text-pacificBlue hover:text-pacificBlueDeeper border-pacificBlue hover:border-pacificBlueDeeper hover:bg-cotton": !active,
        },
      )}
    >
      {text}
    </button>
  );
};
export default FilterButton;
