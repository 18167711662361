import React from "react";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";

import {
  useCreateNewRelationshipAndSetActive,
  useGetActiveRelationship,
  useGetSenderByUuid,
  useUpdateActiveRelationship,
  useUpdateActiveStep,
} from "../Relationship/relationshipHooks";
import { Person } from "../Person/types";
import { useUpdateEntity } from "../Person/personHooks";

import { v4 as uuidv4 } from "uuid";
import EntityPage from "../Entities/EntityPage";

interface Props extends RouteComponentProps {
  relationshipUuid?: string;
}

const SenderDetailsPage: React.FC<Props> = ({ relationshipUuid }) => {
  const { updateEntity } = useUpdateEntity();
  const updateActiveStep = useUpdateActiveStep();
  const updateActiveRelationship = useUpdateActiveRelationship();

  React.useEffect(() => {
    if (relationshipUuid) {
      updateActiveRelationship(relationshipUuid);
    }
  }, [updateActiveRelationship, relationshipUuid]);

  const activeRelationship = useGetActiveRelationship();

  const sender = useGetSenderByUuid(activeRelationship?.senderUuid);

  const location = useLocation();

  const locationState: any = location.state;

  const createNewRelationshipAndSetActive = useCreateNewRelationshipAndSetActive();

  React.useEffect(() => {
    updateActiveStep("senderDetails");
  }, [updateActiveStep]);

  const backClick = () => {
    navigate("/relationships");
  };

  const saveClick = (values: Person) => {
    const senderUuid = sender?.uuid ? sender.uuid : uuidv4();
    if (!activeRelationship) {
      createNewRelationshipAndSetActive(senderUuid, null);
      updateEntity(senderUuid, values);
      return;
    }

    updateEntity(senderUuid, values);
    navigate(`/relationships/${relationshipUuid}/receiver-details`, { state: { saveClicked: true } });
  };

  React.useEffect(() => {
    if (locationState?.saveClicked) {
      window.scroll({ top: 0, behavior: "smooth" });
    }
  }, [locationState]);

  if (!sender && relationshipUuid) {
    return null;
  }

  return (
    <EntityPage
      person={sender}
      saveClick={saveClick}
      backClick={backClick}
      title="New transfer • Sender details"
      saveButtonText={"Save & next"}
    />
  );
};

export default SenderDetailsPage;
