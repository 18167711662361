import React from "react";
import { navigate, useLocation } from "@reach/router";

import classNames from "classnames";

interface Props {
  path: string;
  pathPrefix: string;
  className?: string;
  home?: boolean;
  activeContent: string | React.ReactElement;
  inactiveContent: string | React.ReactElement;
}

const TabButton = ({ activeContent, inactiveContent, path, pathPrefix, className, home = false }: Props) => {
  const location = useLocation();
  const { pathname } = location;
  const active = pathname.indexOf(pathPrefix) !== -1 || (home && pathname === "/");

  const handleClick = () => {
    navigate(path);
  };

  return (
    <button
      onClick={handleClick}
      className={classNames(
        "flex items-center px-2 rounded-t h-10 text-sm font-normal focus:outline-none",
        {
          "bg-tasman text-white hover:bg-tasmanDeep": active,
          "text-pacificBlue hover:text-pacificBlueDeep": !active,
        },
        className,
      )}
    >
      {active ? activeContent : inactiveContent}
    </button>
  );
};

export default TabButton;
