import React from "react";

interface Props {
  direction?: "up" | "down";
}

const SortArrow = ({ direction = "down" }: Props) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" transform={`rotate(${direction === "up" ? 180 : 0})`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.4462 7.38462C2.26159 7.56923 2.26159 7.82308 2.4462 8.0077L5.90774 11.4C6.09235 11.5846 6.36928 11.5846 6.55389 11.4L10.0385 8.0077C10.2231 7.82308 10.2231 7.56923 10.0385 7.38462L9.39235 6.76154C9.20774 6.57693 8.93082 6.57693 8.7462 6.76154L7.66159 7.82308C7.47697 8.0077 7.15389 7.89231 7.15389 7.61539V1.38462C7.15389 1.15385 6.9462 0.92308 6.69235 0.92308H5.76928C5.51543 0.92308 5.30774 1.17693 5.30774 1.38462V7.61539C5.30774 7.89231 4.98466 8.0077 4.80005 7.82308L3.71543 6.76154C3.53082 6.57693 3.25389 6.57693 3.06928 6.76154L2.4462 7.38462Z"
      fill="#706E6B"
    />
  </svg>
);

export default SortArrow;
