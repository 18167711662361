import React from "react";
import LoadingSpinner from "../UtilComponents/LoadingSpinner";
import ADBLogo from "../Assets/logo/ADBLogo";

const Loading = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center flex-col">
      <LoadingSpinner />
      <div className="h-2" />
      <ADBLogo />
    </div>
  );
};

export default Loading;
