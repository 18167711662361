import React from "react";

const AccountIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 21.5V22.6C25 23.9 23.9 25 22.6 25H3.4C2.1 25 1 23.9 1 22.6V21.5C1 18.6 4.4 16.8 7.6 15.4L7.9 15.25C8.15 15.15 8.4 15.15 8.65 15.3C9.95 16.15 11.4 16.6 12.95 16.6C14.5 16.6 16 16.1 17.25 15.3C17.5 15.15 17.75 15.15 18 15.25L18.3 15.4C21.6 16.8 25 18.55 25 21.5ZM13 1C16.3 1 18.95 3.95 18.95 7.6C18.95 11.25 16.3 14.2 13 14.2C9.7 14.2 7.05 11.25 7.05 7.6C7.05 3.95 9.7 1 13 1Z"
      fill="#0070D2"
    />
  </svg>
);

export default AccountIcon;
