import React from "react";
import ActionButton from "./ActionButton";
import EscalateIcon from "./icons/EscalateIcon";
import MonitorContact from "./icons/MonitorContact";
import Plus from "../Assets/Plus";
import ViewConsentsIcon from "./icons/ViewConsentsIcon";
import ContactHistoryIcon from "./icons/ContactHistoryIcon";

const Actions = () => {
  return (
    <div className="border-r">
      <div className="font-bold ml-4 flex items-center">Actions</div>
      <ActionButton text={"Escalate concern"} icon={<EscalateIcon />} extraClassNames={"bg-amethyst hover:bg-amethystDarker"} />
      <ActionButton text={"Monitor contact"} icon={<MonitorContact />} extraClassNames={"bg-amethyst hover:bg-amethystDarker"} />
      <ActionButton text={"New Register Item"} icon={<Plus />} />
      <ActionButton text={"View Consents"} icon={<ViewConsentsIcon />} />
      <ActionButton text={"Contact History"} icon={<ContactHistoryIcon />} />
    </div>
  );
};

export default Actions;
