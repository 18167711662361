import React from "react";
import ADBLogo from "../Assets/logo/ADBLogo";
import { baseUrl } from "../API/API";

const SignInPage = () => {
  const handleLogin = () => {
    // @ts-ignore
    window.location = `${baseUrl}/auth/login`;
  };
  return (
    <div className="w-full h-screen flex justify-center items-center flex-col">
      <div className="w-64 h-56 border flex items-center flex-col rounded border-jumbo">
        <div className="rounded h-24 w-full bg-foundation text-white flex justify-center items-center text-2xl">
          <ADBLogo />
        </div>
        <div className="flex flex-1 justify-center items-center">
          <button
            onClick={handleLogin}
            className="bg-tasman hover:bg-tasmanDeep text-white text-center bg-foundation h-12 w-56 rounded"
          >
            SIGN IN
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
