import React from "react";

const Plus = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.23077 7.92308H13.3077C13.5539 7.92308 13.7692 7.7077 13.7692 7.46154V6.53847C13.7692 6.29231 13.5539 6.07693 13.3077 6.07693H8.23077C8.04616 6.07693 7.92308 5.95385 7.92308 5.76924V0.692312C7.92308 0.446159 7.7077 0.230774 7.46154 0.230774H6.53847C6.29231 0.230774 6.07693 0.446159 6.07693 0.692312V5.76924C6.07693 5.95385 5.95385 6.07693 5.76924 6.07693H0.692312C0.446159 6.07693 0.230774 6.29231 0.230774 6.53847V7.46154C0.230774 7.7077 0.446159 7.92308 0.692312 7.92308H5.76924C5.95385 7.92308 6.07693 8.04616 6.07693 8.23077V13.3077C6.07693 13.5539 6.29231 13.7692 6.53847 13.7692H7.46154C7.7077 13.7692 7.92308 13.5539 7.92308 13.3077V8.23077C7.92308 8.04616 8.04616 7.92308 8.23077 7.92308Z"
        fill="white"
      />
    </svg>
  );
};

export default Plus;
