import React from "react";
import classNames from "classnames";

interface Props {
  disabled?: boolean;
}

const BackArrow = ({ disabled = false }: Props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="8" fill="white" />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
        <rect width="16" height="16" rx="8" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M12.5061 7.51021H6.00162C5.82529 7.51021 5.74692 7.2947 5.86448 7.17715L7.74529 5.29633C7.86284 5.17878 7.86284 5.00246 7.74529 4.88491L7.31427 4.45389C7.19672 4.33633 7.02039 4.33633 6.90284 4.45389L3.47427 7.88246C3.35672 8.00001 3.35672 8.17633 3.47427 8.29389L6.90284 11.7225C7.02039 11.84 7.19672 11.84 7.31427 11.7225L7.7257 11.311C7.84325 11.1935 7.84325 11.0172 7.7257 10.8996L5.84488 9.01878C5.72733 8.90123 5.8057 8.68572 5.98203 8.68572H12.4865C12.6433 8.68572 12.7804 8.54858 12.7804 8.39185V7.80409C12.8 7.64736 12.6628 7.51021 12.5061 7.51021Z"
          className={classNames("fill-current text-stone", { "group-hover:text-jumbo": !disabled })}
        />
      </g>
    </svg>
  );
};

export default BackArrow;
