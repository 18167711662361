import { Maybe } from "../types";

const getBase64 = (localImageUrl: Maybe<string>): Promise<string> => {
  return new Promise(async (resolve) => {
    if (!localImageUrl) {
      resolve("");
      return;
    }
    let file = await fetch(localImageUrl).then((r) => r.blob());
    let fileInfo;
    let baseUrl: any = "";
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      console.log("Called", reader);
      if (typeof reader?.result === "string") {
        baseUrl = reader?.result?.split(",")[1];
      }

      console.log(baseUrl);
      resolve(baseUrl);
    };

    console.log(fileInfo);
  });
};

export default getBase64;
