import React from "react";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";
import EntityPage from "./EntityPage";
import { useGetSenderByUuid } from "../Relationship/relationshipHooks";
import { Person } from "../Person/types";
import { v4 as uuidv4 } from "uuid";
import { useUpdateEntity } from "../Person/personHooks";

interface Props extends RouteComponentProps {
  uuid?: string;
}

const Entity: React.FC<Props> = ({ uuid }) => {
  const location = useLocation();

  const { loading, updateEntity } = useUpdateEntity();

  React.useEffect(() => {
    if (!uuid) {
      navigate("/");
    }
  }, [uuid]);

  if (!uuid) {
    return null;
  }

  const backClick = () => {
    navigate("/entities");
  };

  const saveClick = (values: Person) => {
    const senderUuid = person?.uuid ? person.uuid : uuidv4();
    updateEntity(senderUuid, values, false);
  };

  const person = useGetSenderByUuid(uuid);

  return (
    <div>
      <EntityPage
        saveButtonText={"Update"}
        person={person}
        backClick={backClick}
        saveClick={saveClick}
        title={`${person?.firstName} ${person?.lastName}`}
        editing
        loading={loading}
      />
    </div>
  );
};

export default Entity;
