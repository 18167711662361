import React from "react";

const Watch = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#7F8DE1" />
      <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" rx="12" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 7.46667C15.0133 7.46667 17.6267 9.22667 18.88 11.76C18.96 11.92 18.96 12.08 18.88 12.24C17.6267 14.7733 15.0133 16.5333 12 16.5333C8.98666 16.5333 6.37333 14.7733 5.12 12.24C5.04 12.1067 5.04 11.92 5.12 11.76C6.37333 9.22667 8.98666 7.46667 12 7.46667ZM9.06666 12C9.06666 13.6267 10.3733 14.9333 12 14.9333C13.6267 14.9333 14.9333 13.6267 14.9333 12C14.9333 10.3733 13.6267 9.06667 12 9.06667C10.3733 9.06667 9.06666 10.3733 9.06666 12Z"
          fill="white"
        />
        <path
          d="M12 10.1333C10.96 10.1333 10.1333 10.96 10.1333 12C10.1333 13.04 10.96 13.8667 12 13.8667C13.04 13.8667 13.8667 13.04 13.8667 12C13.8667 10.96 13.04 10.1333 12 10.1333Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default Watch;
