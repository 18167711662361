import React from "react";
import classNames from "classnames";

interface Props {
  checked: boolean;
  className?: string;
  onClick: any;
}

const Checkbox = ({ checked, onClick, className }: Props) => {
  return (
    <input
      type="checkbox"
      className={classNames("cursor-pointer form-checkbox h-4 w-4 text-slate focus:ring-transparent rounded", className)}
      checked={checked}
      onChange={onClick}
    />
  );
};

export default Checkbox;
