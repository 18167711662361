import React from "react";

const CertifiedTick = () => {
  return (
    <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.475 16.725L0.225 8.425C-0.075 8.125 -0.075 7.625 0.225 7.325L1.325 6.225C1.625 5.925 2.125 5.925 2.425 6.225L8.625 12.475C8.825 12.675 9.175 12.675 9.375 12.475L21.525 0.225C21.825 -0.075 22.325 -0.075 22.625 0.225L23.725 1.325C24.025 1.625 24.025 2.125 23.725 2.425L9.575 16.725C9.275 17.075 8.775 17.075 8.475 16.725Z"
        fill="#04844B"
      />
    </svg>
  );
};

export default CertifiedTick;
