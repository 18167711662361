import { Maybe, Nullable } from "../types";

export const stepToPathnameMap = (relationshipUuid: string) => ({
  transferDetails: `/relationships/${relationshipUuid}/mti`,
  senderDetails: `/relationships/${relationshipUuid}/sender-details`,
  receiverDetails: `/relationships/${relationshipUuid}/receiver-details`,
  complete: `/relationships/${relationshipUuid}/sender-details`,
});

export const pathnameToStepMap = (relationshipUuid: string) => ({
  [`/relationships/${relationshipUuid}/mti`]: "transferDetails",
  [`/relationships/${relationshipUuid}/sender-details`]: "senderDetails",
  [`/relationships/${relationshipUuid}/receiver-details`]: "receiverDetails",
});

export const undefinedToNull = <T>(arg: Maybe<T>): Nullable<T> => {
  return arg ? arg : null;
};

export const replaceWhitespace = (text: string, replacement: string) => {
  return text.replace(/\s/g, replacement);
};
