import React from "react";
import SortArrow from "../../utils/SortArrow";
import { useGetRelationships, useSortedRelationships } from "./relationshipHooks";
import { Nullable } from "../../types";
import { StatusFilters } from "../Dashboard/types";
import { DateFilters } from "../Entities/types";
import RelationshipRow from "../Dashboard/RelationshipRow";
import LoadingSpinner from "../../utils/LoadingSpinner";
import classNames from "classnames";

interface Props {
  dashboard?: boolean;
  searchValue: Nullable<string>;
  statusFilter?: StatusFilters;
  dateFilter?: DateFilters;
}

const RelationshipTable = ({ searchValue, statusFilter, dateFilter, dashboard = true }: Props) => {
  const [checked, setChecked] = React.useState(false);

  const [sortDirection, setSortDirection] = React.useState<"asc" | "desc">("desc");

  const [relationships, setRelationships] = React.useState<any>({});

  const getSortedRelationships = useSortedRelationships();

  const { loading } = useGetRelationships();

  React.useEffect(() => {
    const relationships = getSortedRelationships(sortDirection, searchValue, statusFilter, dateFilter);
    setRelationships(relationships);
  }, [loading, sortDirection, searchValue, statusFilter, dateFilter]);

  return (
    <div className="flex flex-col pt-4">
      <table className="text-sm">
        <tbody>
          <tr className="bg-cloud hover:bg-cotton h-8 border-b text-slate hover:text-coal cursor-default">
            <th className="text-left pl-4 w-4">
              <div className={classNames("pl-2 flex items-center", { invisible: !loading })}>
                <LoadingSpinner />
              </div>
            </th>
            <th className="text-left pl-4">
              <div className="flex items-center">
                <div>Sender Name</div>
                <div
                  className="ml-2 cursor-pointer"
                  onClick={() => setSortDirection(sortDirection === "asc" ? "desc" : "asc")}
                >
                  <SortArrow direction={sortDirection === "asc" ? "up" : "down"} />
                </div>
              </div>
            </th>
            <th className="text-left">Receiver Name</th>
            {dashboard ? (
              <>
                <th className="text-left">Created Date</th>
                <th className="text-left">Status</th>
                <th className="text-left">View</th>
              </>
            ) : (
              <>
                <th className="text-left">Amount</th>
                <th className="text-left">Expiry Date</th>
                <th />
              </>
            )}
          </tr>
          {Object.keys(relationships).map((relationshipKey) => {
            return <RelationshipRow key={relationshipKey} relationship={relationships[relationshipKey]} />;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RelationshipTable;
