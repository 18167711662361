import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import SearchTable from "./SearchTable";
import NewContact from "./NewContact";
import CustomButton from "../Viewer/CustomButton";
import BackArrow from "../Assets/BackArrow";
import { Nullable } from "../../types";
import { useCreateNewRelationshipAndSetActive } from "../Relationship/relationshipHooks";

const SearchPage: React.FC<RouteComponentProps> = () => {
  const createNewRelationshipAndSetActive = useCreateNewRelationshipAndSetActive();

  const [senderUuid, setSenderUuid] = React.useState<Nullable<string>>(null);

  const [receiverUuid, setReceiverUuid] = React.useState<Nullable<string>>(null);

  const onNextClick = () => {
    if (senderUuid !== receiverUuid || senderUuid === null || receiverUuid === null) {
      createNewRelationshipAndSetActive(senderUuid, receiverUuid);
      navigate("/relationships/new/sender-details", { state: { saveClicked: true } });
    } else {
      alert("The sender cannot be the same person as the receiver.");
    }
  };

  const backClick = () => {
    navigate("/relationships");
  };

  return (
    <div className="flex flex-col flex-1 mt-4">
      <div className="flex font-bold">Search for contact/relationship</div>
      <div className="flex flex-col flex-1 mb-12">
        <div className="pt-8 flex flex-1">
          <div className="">
            <SearchTable title="Sender" checkedPersonUuid={senderUuid} setCheckedPersonUuid={setSenderUuid} />
            <NewContact
              text="Create new Sender Contact"
              checkedPersonUuid={senderUuid}
              setCheckedPersonUuid={setSenderUuid}
            />
          </div>
          <div className="w-4 lg:w-20" />
          <div className="mr-4">
            <SearchTable title="Receiver" checkedPersonUuid={receiverUuid} setCheckedPersonUuid={setReceiverUuid} />
            <NewContact
              text="Create new Receiver Contact"
              setCheckedPersonUuid={setReceiverUuid}
              checkedPersonUuid={receiverUuid}
            />
          </div>
        </div>
      </div>
      <div className="w-full bg-sky h-24 mb-10 flex justify-between items-center">
        <CustomButton type="button" theme="secondary" disabled={false} className={"ml-8"} onClick={backClick}>
          <div className="flex items-center">
            <div className="mr-2">
              <BackArrow />
            </div>
            <div>Back</div>
          </div>
        </CustomButton>
        <CustomButton type="submit" disabled={false} onClick={onNextClick} className={"mr-8"}>
          <div>Save & next</div>
        </CustomButton>
      </div>
    </div>
  );
};

export default SearchPage;
