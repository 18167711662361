import axios from "axios";
import {
  CreateCustomerApi,
  CreateRelationshipApi,
  CreateRelationshipResponse,
  CustomersApi,
  RelationshipsAPI,
  SalesforceAccountUser,
} from "./types";

axios.defaults.withCredentials = true;

export const baseUrl = process.env["REACT_APP_API_URL"];

export const Api = {
  getRelationships: async (options: any, searchFields: { bankName: string }): Promise<RelationshipsAPI | void> => {
    try {
      const relationships = await axios.post(`${baseUrl}/relationship/find`, {
        ...searchFields,
        cancelToken: options.cancelToken,
      });
      return relationships.data;
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log("Request cancelled");
      } else {
        throw e;
      }
    }
  },
  getCustomers: async (searchFields: { bankName: string }): Promise<CustomersApi> => {
    const customers = await axios.post(`${baseUrl}/customer/find`, searchFields);
    return customers.data;
  },
  createRelationship: async (activeRelationship: CreateRelationshipApi): Promise<CreateRelationshipResponse> => {
    const relationship = await axios.post(`${baseUrl}/relationship/create`, activeRelationship);
    return relationship.data;
  },
  createCustomer: async (customer: CreateCustomerApi): Promise<CreateRelationshipResponse> => {
    const relationship = await axios.post(`${baseUrl}/customer/create`, customer);
    return relationship.data;
  },
  getUser: async (): Promise<SalesforceAccountUser> => {
    const response = await axios.get(`${baseUrl}/auth/whoami`);
    return response.data;
  },
};
