import React from "react";
import Select from "react-select";
import { Maybe, Nullable } from "../../types";

interface Props {
  options: { value: string; label: string }[];
  onChange: any;
  width?: number;
  selectedValue?: { value: Nullable<string>; label: Nullable<string> };
}

const customStyles = (width: Maybe<number>) => {
  return {
    control: (provided: any, state: any) => ({
      ...provided,
      width,
      background: "#fff",
      borderColor: "#DDDBDA",
      minHeight: "32px",
      height: "32px",
      color: "#3E3E3C",
      fontSize: 14,
      boxShadow: state.isFocused ? null : null,
    }),

    option: (provided: any) => ({
      ...provided,
      color: "#3E3E3C",
    }),

    singleValue: (provided: any) => ({
      ...provided,
      color: "#3E3E3C",
      paddingBottom: "4px",
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: "32px",
      padding: "0 10px",
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state: any) => ({
      display: "none",
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: "32px",
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      paddingBottom: "2px",
    }),
  };
};

const SelectInput = ({ options, onChange, width, selectedValue }: Props) => (
  <Select
    placeholder={"Select..."}
    value={selectedValue?.value ? selectedValue : null}
    isSearchable={false}
    options={options}
    className="h-6 text-slate focus:outline-none"
    styles={customStyles(width)}
    onChange={onChange}
  />
);

export default SelectInput;
