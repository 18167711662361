import SearchRow from "./SearchRow";
import React from "react";
import { useFilteredPersons } from "../Person/personHooks";
import { Nullable } from "../../types";
import QuickFilter from "../../utils/QuickFilter";
import { Person } from "../Person/types";

interface Props {
  title: string;
  setCheckedPersonUuid: (personUuid: Nullable<string>) => void;
  checkedPersonUuid: Nullable<string>;
}

const SearchTable = ({ title, setCheckedPersonUuid, checkedPersonUuid }: Props) => {
  const [searchValue, setSearchValue] = React.useState<Nullable<string>>(null);
  const [persons, setPersons] = React.useState<{ [uuid: string]: Person }>({});

  const getFilteredPersons = useFilteredPersons();

  React.useEffect(() => {
    const persons = getFilteredPersons(searchValue);
    setPersons(persons);
  }, [searchValue]);

  return (
    <div className="w-80 text-slate flex flex-col mb-2">
      <div className="tracking-wider uppercase text-sm mb-4 cursor-default">{title}</div>
      <div className="mb-7">
        <QuickFilter onChange={(e) => setSearchValue(e.target.value)} placeholder="Quick filter" />
      </div>
      <div className="overflow-auto h-40">
        <table className="text-xs w-full">
          <thead>
            <tr className="bg-cloud hover:bg-cotton h-8 border-b text-slate sticky top-0 z-10 text-slate hover:text-coal">
              <th className="text-left pl-4 w-4">{/*<Checkbox checked={checked} setChecked={setChecked} />*/}</th>
              <th className="text-left pl-4">Name</th>
              <th className="text-left pl-4">Date of birth</th>
            </tr>
          </thead>
          <tbody className="">
            {Object.keys(persons).map((personUuid: string) => {
              return (
                <SearchRow
                  key={personUuid}
                  person={persons[personUuid]}
                  setChecked={() => setCheckedPersonUuid(personUuid)}
                  checkedPersonUuid={checkedPersonUuid}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SearchTable;
