import SearchIcon from "../domains/Search/SearchIcon";
import React from "react";
import classNames from "classnames";

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  placeholder: string;
}

const QuickFilter = ({ onChange, className, placeholder }: Props) => {
  return (
    <div className={classNames("flex border rounded flex items-center pl-2 bg-white hover:bg-snow", className)}>
      <SearchIcon />
      <input
        placeholder={placeholder}
        className="pl-1 py-2 text-xs text-jumbo flex flex-1 focus:outline-none rounded bg-white hover:bg-snow"
        onChange={onChange}
      />
    </div>
  );
};

export default QuickFilter;
