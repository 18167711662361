import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";

interface Props extends RouteComponentProps {
  children: JSX.Element[] | JSX.Element;
  backPath?: string;
}

const ViewerWindow: React.FC<Props> = ({ children }: Props) => {
  const location = useLocation();

  const { pathname } = location;

  React.useEffect(() => {
    if (pathname === "/") {
      navigate("/relationships");
    }
  }, []);

  return (
    <div className="w-full h-screen flex flex-col justify-between">
      <Header />
      <div className="flex flex-1 justify-center">
        {children &&
          React.Children.map(children, (child: any) =>
            React.cloneElement(child, {
              className: "flex w-2/3 py-8",
            }),
          )}
      </div>
      <Footer />
    </div>
  );
};

export default ViewerWindow;
