import React from "react";
import { navigate } from "@reach/router";

import { useAppDispatch } from "../../hooks";
import { Api } from "../API/API";
import {
  useGetActiveReceiver,
  useGetActiveRelationship,
  useGetActiveSender,
  useUpdateActiveStep,
  useUpdateTransferDetails,
} from "../Relationship/relationshipHooks";
import { CreateRelationshipApi } from "../API/types";
import { undefinedToNull } from "../utils";
import { entityToCustomerApi } from "../Entities/utils";
import { toast } from "react-toastify";
import { BankNotSupportedMessage } from "../constants";
import { useSelector } from "react-redux";
import { getAccountUser } from "../Account/accountSelectors";

export const useCreateRelationship = () => {
  const dispatch = useAppDispatch();
  const updateActiveStep = useUpdateActiveStep();
  const updateTransferDetails = useUpdateTransferDetails();
  const activeRelationship = useGetActiveRelationship();
  const [loading, setLoading] = React.useState(false);

  const [submitted, setSubmitted] = React.useState(false);

  const activeRelationshipAmount = activeRelationship?.amount;

  const sender = useGetActiveSender();

  const receiver = useGetActiveReceiver();

  const accountUser = useSelector(getAccountUser);

  const createRelationship = async () => {
    setLoading(true);
    try {
      if (!accountUser?.bankName) {
        alert(BankNotSupportedMessage);
        return;
      }
      if (!sender || !receiver || !activeRelationship || !activeRelationshipAmount) {
        return;
      }

      const senderDetails = await entityToCustomerApi(sender);
      const receiverDetails = await entityToCustomerApi(receiver);

      const relationShip: CreateRelationshipApi = {
        sender: senderDetails,
        receiver: receiverDetails,
        reason: undefinedToNull(activeRelationship.reason),
        frequency: undefinedToNull(activeRelationship.frequency),
        currencyCode: undefinedToNull(activeRelationship.currencyCode),
        amount: undefinedToNull(activeRelationship.amount),
        status: undefinedToNull(activeRelationship.status),
        bankName: accountUser.bankName,
        uuid: activeRelationship.relationshipUuid,
      };

      await Api.createRelationship(relationShip);
      toast.success("Relationship created successfully");
      navigate("/relationships");
    } catch (e) {
      console.log(e);
      toast.error(e?.response?.data?.msg);
    } finally {
      setLoading(false);
      setSubmitted(false);
    }
  };

  return {
    loading,
    createRelationship: React.useCallback(
      async (transferDetails: any) => {
        updateActiveStep("complete");
        updateTransferDetails(transferDetails);
        createRelationship();
        setSubmitted(true);
      },
      [dispatch, updateActiveStep, updateTransferDetails, activeRelationship, sender, receiver, accountUser],
    ),
  };
};
