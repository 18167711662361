import React from "react";
import Steps from "./Steps";
import Actions from "./Actions";

const RightPanel = () => {
  return (
    <div className="mt-18 ml-8">
      <Steps />
      <div className="h-12" />
      <Actions />
    </div>
  );
};

export default RightPanel;
