import React from "react";

const SearchIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2609 13.9378L11.1378 9.84551C11.9686 8.67628 12.3993 7.19936 12.1839 5.63013C11.8147 2.98397 9.66087 0.891666 6.98395 0.645513C3.35318 0.276282 0.276256 3.35321 0.645487 7.01474C0.891641 9.6609 2.98395 11.8455 5.6301 12.2147C7.19933 12.4301 8.67626 11.9994 9.84549 11.1686L13.9378 15.2609C14.1224 15.4455 14.3993 15.4455 14.5839 15.2609L15.2301 14.6147C15.4147 14.4301 15.4147 14.1224 15.2609 13.9378ZM2.46087 6.43013C2.46087 4.24551 4.24549 2.4609 6.4301 2.4609C8.61472 2.4609 10.3993 4.24551 10.3993 6.43013C10.3993 8.61474 8.61472 10.3994 6.4301 10.3994C4.24549 10.3994 2.46087 8.64551 2.46087 6.43013Z"
      fill="#B0ADAB"
    />
  </svg>
);

export default SearchIcon;
