import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import CertifiedPerson from "./CertifiedPerson";
import ADBLogo from "../Assets/logo/ADBLogo";
import TICCCompanyLogoSmall from "../Assets/logo/TICCCompanyLogoSmall";
import {
  useGetActiveReceiver,
  useGetActiveRelationship,
  useGetActiveSender,
  useUpdateActiveRelationship,
} from "../Relationship/relationshipHooks";
import moment from "moment";

interface Props extends RouteComponentProps {
  relationshipUuid?: string;
}

const CertificateDocument: React.FC<Props> = ({ relationshipUuid }) => {
  const handleClick = () => {
    navigate(`/relationships`);
  };

  const updateActiveRelationship = useUpdateActiveRelationship();

  React.useEffect(() => {
    if (relationshipUuid) {
      updateActiveRelationship(relationshipUuid);
    }
  }, [updateActiveRelationship, relationshipUuid]);

  const relationship = useGetActiveRelationship();

  const sender = useGetActiveSender();

  const receiver = useGetActiveReceiver();

  if (!relationship) {
    return null;
  }

  return (
    <div>
      <div className="pl-4">
        <button className="text-pacificBlue text-xs mb-1" onClick={handleClick}>
          ← Relationships
        </button>

        <div>
          <div className="pb-8">Certification Detail</div>

          <div className="flex justify-between flex-col border rounded w-200 h-220 bg-gray-100">
            <div>
              <div className="flex">
                <div className="ml-4 w-36 h-38 bg-tasman flex justify-center items-center flex-col text-white font-bold relative">
                  <div className="w-30 flex flex-col justify-center items-center">
                    <div style={{ height: 2 }} className="w-full bg-white" />
                    <div className="text-lg">
                      {relationship && moment(relationship.createdAt).add("1", "years").format("DD MMMM").toUpperCase()}
                    </div>
                    <div className="flex justify-between items-end">
                      <div style={{ height: 2, marginBottom: 7 }} className="w-4 bg-white mr-1" />
                      <div className="text-2xl">
                        {relationship && moment(relationship.createdAt).add("1", "years").format("YYYY")}
                      </div>
                      <div style={{ height: 2, marginBottom: 7 }} className="w-4 bg-white ml-1" />
                    </div>
                  </div>
                  <div
                    className="w-0 h-0 bg-coriander absolute border-gray-100"
                    style={{
                      bottom: -10,
                      borderTopWidth: 10,
                      borderRightWidth: 74,
                      borderLeftWidth: 74,
                      borderTopColor: "#007DB6",
                    }}
                  />
                </div>
                <div className="ml-24">
                  <div className="mt-14 font-bold"></div>
                  <div className="text-4xl font-bold">Certified Relationship</div>
                </div>
              </div>
              <div className="flex justify-between mt-8 h-40 bg-white items-center">
                <div className="ml-24">{sender && <CertifiedPerson person={sender} type={"sender"} />}</div>
                <div className="mr-24">{receiver && <CertifiedPerson person={receiver} type={"receiver"} />}</div>
              </div>
              <div className="flex justify-center mt-8">
                <div className="text-sm flex items-center flex-col">
                  <div className="font-thin tracking-wider">AMOUNT</div>
                  <div className="text-4xl my-2">
                    {relationship.currencyCode}${relationship.amount}
                  </div>
                  <div className="font-bold text-xs">Per {`${relationship.frequency}`}</div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="flex justify-between mt-8 w-2/3">
                  <div className="ml-24">
                    <div className="text-sm">Date issued</div>
                    <div className="text-lg">
                      {relationship && moment(relationship.createdAt).format("DD MMMM, YYYY")}
                    </div>
                  </div>
                  <div className="mr-24">
                    <div className="text-sm">Expires</div>
                    <div className="text-lg">
                      {relationship && moment(relationship.createdAt).add("1", "years").format("DD MMMM, YYYY")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-20">
                <div className="w-80">
                  <div className="flex justify-center font-bold text-xxs my-2">Nature and Purpose </div>
                  <div className="flex justify-center text-xxs text-center">
                    {relationship.reason ? relationship.reason : "Not specified"}
                  </div>
                </div>
              </div>
            </div>
            <div className="relative p-8">
              <div className="absolute">
                <div className="w-30">
                  <TICCCompanyLogoSmall />
                </div>
                <div className="text-xxs">
                  TIC Co. certifies all necessary processes and verifications have been carried out to agreed standards.
                </div>
              </div>
              <div className="flex justify-between items-end">
                <div className="text-xxs font-thin">{relationship?.relationshipUuid}</div>
                <ADBLogo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateDocument;
