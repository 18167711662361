import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllPersons } from "./personSelector";
import { Maybe, Nullable } from "../../types";
import { orderBy } from "lodash";
import { Person } from "./types";
import React from "react";
import { updatePerson } from "./personSlice";
import { CreateCustomerApi } from "../API/types";
import { Api } from "../API/API";
import { toast } from "react-toastify";
import { navigate } from "@reach/router";
import { entityToCreateCustomerApi } from "../Entities/utils";

export const usePerson = () => {
  const persons = useAppSelector((state) => getAllPersons(state));
  return React.useCallback(
    (uuid: Maybe<string>) => {
      if (!uuid) {
        return;
      }
      return persons[uuid];
    },
    [persons],
  );
};

export const useAllPersons = () => {
  return useAppSelector(getAllPersons);
};

export const useFilteredPersons = () => {
  const persons = useAppSelector(getAllPersons);

  const personKeys = Object.keys(persons);

  return React.useCallback(
    (searchValue: Nullable<string>) => {
      const orderedPersons = orderBy(Object.values(persons), ["lastName", "firstName"], ["asc", "asc"]);

      if (!searchValue) {
        return orderedPersons.reduce((accum, person) => {
          return {
            ...accum,
            [person.uuid]: person,
          };
        }, {});
      }

      const filteredPersons = orderedPersons.filter((person) => {
        const fullName = `${person.firstName} ${person.lastName}`;
        return fullName.toLowerCase().includes(searchValue.toLowerCase());
      });

      return filteredPersons.reduce((accum, person) => {
        return {
          ...accum,
          [person.uuid]: person,
        };
      }, {});
    },
    [persons, personKeys],
  );
};

export const useUpdateEntity = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = React.useState(false);

  const updateEntity = async (entity: CreateCustomerApi) => {
    setLoading(true);
    try {
      await Api.createCustomer(entity);
      toast.success("Customer details updated");
      navigate(`/entities`, { state: { saveClicked: true } });
    } catch (e) {
      console.log(e);
      toast.error("Failed to update customer!");
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    updateEntity: React.useCallback(
      (personUuid: string, values: Person, localOnly = true) => {
        if (!localOnly) {
          updateEntity(entityToCreateCustomerApi(values));
        }

        dispatch(updatePerson({ ...values, uuid: personUuid }));
      },
      [dispatch],
    ),
  };
};
