import React from "react";
import classNames from "classnames";

interface Props {
  isActive?: Boolean;
}

const Tick = ({ isActive = false }: Props) => {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83074 10.3692L0.753818 5.26153C0.569203 5.07692 0.569203 4.76923 0.753818 4.58461L1.43074 3.90769C1.61536 3.72307 1.92305 3.72307 2.10766 3.90769L5.92305 7.75384C6.04613 7.87692 6.26151 7.87692 6.38459 7.75384L13.8615 0.215381C14.0461 0.0307657 14.3538 0.0307657 14.5384 0.215381L15.2154 0.892304C15.4 1.07692 15.4 1.38461 15.2154 1.56923L6.50766 10.3692C6.32305 10.5846 6.01536 10.5846 5.83074 10.3692V10.3692Z"
        className={classNames("fill-current", {
          "text-ocean group-hover:text-oceanDarker": isActive,
          "text-jumbo group-hover:text-jumboDarker": !isActive,
        })}
      />
    </svg>
  );
};

export default Tick;
