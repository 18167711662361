import React from "react";

const ViewConsentsIcon = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7406 13.3077C13.4713 11.7308 11.856 10.9231 10.5483 10.3462C9.97134 10.0769 9.16365 9.76923 8.93288 9.07692C8.77903 8.69231 8.85596 8.30769 9.04826 7.96154C9.39442 7.61539 9.70211 7.30769 9.97134 6.88462C10.0483 6.76923 10.0867 6.69231 10.1636 6.57692C10.9329 5.46154 11.0483 3.92308 10.5867 2.65385C10.1252 1.26923 8.89442 0.346155 7.39442 0.307693H7.16365C5.66365 0.346155 4.43288 1.26923 3.97134 2.65385C3.5098 3.96154 3.62519 5.46154 4.39442 6.57692C4.43288 6.69231 4.5098 6.76923 4.58673 6.88462C4.85596 7.30769 5.20211 7.61539 5.5098 7.96154C5.66365 8.30769 5.74057 8.69231 5.62519 9.07692C5.39442 9.76923 4.58673 10.0769 4.0098 10.3462C2.70211 10.9231 1.08673 11.7308 0.817496 13.3077C0.548265 14.6154 1.43288 15.9231 2.8175 15.9231H11.8944C13.1252 15.9231 14.0098 14.6154 13.7406 13.3077ZM15.9329 1.92308C17.5098 1.84616 18.779 3.07692 18.779 4.65385C18.779 4.76923 18.7021 4.80769 18.6252 4.80769H17.9329C17.8175 4.80769 17.779 4.76923 17.779 4.65385V4.69231C17.779 3.76923 17.0867 3 16.2021 2.92308C15.2021 2.84616 14.3944 3.65385 14.3944 4.61539C14.3944 4.73077 14.3175 4.76923 14.2406 4.76923H13.5483C13.4713 4.76923 13.3944 4.69231 13.3944 4.61539C13.3944 3.23077 14.5098 2 15.9329 1.92308V1.92308ZM18.779 5.5H13.356C12.9713 5.5 12.6636 5.80769 12.6636 6.19231V9.42308C12.6636 9.80769 12.9713 10.1154 13.356 10.1154H18.779C19.1636 10.1154 19.4713 9.80769 19.4713 9.42308V6.19231C19.4713 5.80769 19.1636 5.5 18.779 5.5ZM16.856 8.84615C16.8944 8.96154 16.8175 9.07692 16.7021 9.07692H15.4329C15.3175 9.07692 15.2406 8.96154 15.279 8.84615L15.5867 7.84615C15.3175 7.69231 15.1637 7.38462 15.2406 7.03846C15.3175 6.73077 15.5483 6.46154 15.8944 6.38462C16.4329 6.26923 16.8944 6.65385 16.8944 7.19231C16.8944 7.46154 16.7406 7.73077 16.5483 7.84615L16.856 8.84615V8.84615Z"
        fill="white"
      />
    </svg>
  );
};

export default ViewConsentsIcon