import React from "react";
import { navigate, RouteComponentProps } from "@reach/router";
import RightPanel from "./RightPanel";

interface Props extends RouteComponentProps {
  children: JSX.Element[] | JSX.Element;
}

const NewRelationship: React.FC<Props> = ({ children }: Props) => {
  const handleClick = () => {
    navigate(`/relationships`);
  };

  return (
    <div className="flex justify-center flex-1">
      <div className="w-full lg:w-200">
        <div className="">
          <button className="text-pacificBlue hover:text-pacificBlueDeeper text-xs mb-1 focus:outline-none" onClick={handleClick}>
            ← Relationships
          </button>
        </div>
        <div className="flex justify-between flex-1">
          {/*a hack required because Reach Router does not support passing props to child components which */}
          {/*means that we cannot simply pass in flex-1 as a class*/}
          {children}
          <RightPanel />
        </div>
      </div>
    </div>
  );
};

export default NewRelationship;
