import React from "react";
import "tailwindcss/tailwind.css";
import { Router } from "@reach/router";
import { ToastContainer } from "react-toastify";

import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import ViewerWindow from "./domains/Viewer/ViewerWindow";
import NewRelationship from "./domains/NewRelationship/NewRelationship";
import MoneyTransferInformationPage from "./domains/NewRelationship/MoneyTransferInformationPage";
import ReceiverDetailsPage from "./domains/NewRelationship/ReceiverDetailsPage";
import SenderDetailsPage from "./domains/NewRelationship/SenderDetailsPage";
import Home from "./domains/Home/Home";
import RelationshipsPage from "./domains/Relationship/RelationshipsPage";
import Entities from "./domains/Entities/Entities";
import EntitiesPage from "./domains/Entities/EntitiesPage";
import CertificateDocument from "./domains/Entities/CertificateDocument";
import SearchPage from "./domains/Search/SearchPage";
import Entity from "./domains/Entities/Entity";
import { useGetUser } from "./domains/Account/AccountHooks";
import Loading from "./domains/Viewer/Loading";
import SignInPage from "./domains/Viewer/SignInPage";
import { useSelector } from "react-redux";
import { getAccountUser } from "./domains/Account/accountSelectors";
import { useIsLocalEnv } from "./GlobalHooks";

const App: React.FC = () => {
  const { getUser, isLoading } = useGetUser();
  React.useEffect(() => {
    getUser();
  }, []);

  const user = useSelector(getAccountUser);

  if (!useIsLocalEnv()) {
    if (isLoading) {
      return <Loading />;
    }

    if (!user) {
      return <SignInPage />;
    }
  }

  return (
    <div>
      <ToastContainer hideProgressBar={true} />
      <Router className="flex-1" primary={false}>
        <ViewerWindow path="/">
          <Home path="/" />
          <RelationshipsPage path="/relationships" />
          <SearchPage path="/select-contact" />
          <NewRelationship path="/relationships/new">
            <SenderDetailsPage path="/sender-details" />
          </NewRelationship>
          <CertificateDocument path="/relationships/:relationshipUuid/view" />
          <NewRelationship path="/relationships">
            <SenderDetailsPage path="/:relationshipUuid/sender-details" />
            <MoneyTransferInformationPage path="/:relationshipUuid/mti" />
            <ReceiverDetailsPage path="/:relationshipUuid/receiver-details" />
          </NewRelationship>
          <Entities path="/entities">
            <EntitiesPage path="/" />
            <Entity path="/:uuid" />
            <CertificateDocument path="/view" />
          </Entities>
        </ViewerWindow>
      </Router>
    </div>
  );
};

export default App;
