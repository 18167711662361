import React from "react";
import Checkbox from "./Checkbox";
import { Nullable } from "../../types";

interface Props {
  text: string;
  setCheckedPersonUuid: (personUuid: Nullable<string>) => void;
  checkedPersonUuid: Nullable<string>;
}

const NewContact = ({ text, setCheckedPersonUuid, checkedPersonUuid }: Props) => {
  return (
    <div className="flex items-center text-slate mt-6">
      <Checkbox checked={!checkedPersonUuid} onClick={() => setCheckedPersonUuid(null)} className="ml-4 mr-2" />
      <div className="text-xxs">{text}</div>
    </div>
  );
};

export default NewContact;
