import React from "react";
import UploadIcon from "../Assets/UploadIcon";
import { useDropzone } from "react-dropzone";
import { Nullable } from "../../types";
import CloseIcon from "../Assets/CloseIcon";

export interface DocumentFile {
  fileName: Nullable<string>;
  baseUrl: Nullable<string>;
}

interface Props {
  setBase64File: (data: DocumentFile) => void;
  file: DocumentFile;
}
const DragAndDropBox = ({ setBase64File, file }: Props) => {
  const onDrop = React.useCallback(async (acceptedFiles) => {
    const localObjectUrl = window.URL.createObjectURL(acceptedFiles[0]);

    const fileName = acceptedFiles[0].name;
    setBase64File({ fileName, baseUrl: localObjectUrl });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <>
      {file?.baseUrl ? (
        <div className="border w-56 h-56 flex flex-col justify-center items-center rounded border-dashed relative">
          <div className="text-sm text-slate">{file.fileName}</div>
          <button
            type="button"
            className="absolute top-0 right-0 m-2"
            onClick={() => setBase64File({ fileName: null, baseUrl: null })}
          >
            <CloseIcon />
          </button>
        </div>
      ) : (
        <div className="w-56" {...getRootProps()}>
          <button
            type="button"
            className="border w-56 h-56 flex flex-col justify-center items-center rounded border-dashed"
          >
            <input {...getInputProps()} />
            {isDragActive ? <p>Drop the files here ...</p> : <p>Upload File</p>}
          </button>
        </div>
      )}
    </>
  );
};

export default DragAndDropBox;
