import { AppStore } from "../../rootReducer";

export const getActiveRelationshipUuid = (state: AppStore) => {
  return state.relationshipStore.activeRelationshipUuid;
};

export const getActiveStep = (state: AppStore, relationshipUuid: string) => {
  if (!relationshipUuid) {
    return;
  }
  return state.relationshipStore.relationships[relationshipUuid].activePage;
};

export const getRelationships = (state: AppStore) => {
  return state.relationshipStore.relationships;
};

export const getActiveRelationship = (state: AppStore) => {
  const activeRelationshipUuid = getActiveRelationshipUuid(state);

  if (!activeRelationshipUuid) {
    return;
  }
  return state.relationshipStore.relationships[activeRelationshipUuid];
};
