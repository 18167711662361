import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountIcon from "./AccountIcon";
import { useSelector } from "react-redux";
import { getAccountUser } from "../../Account/accountSelectors";
import { baseUrl } from "../../API/API";

const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleLogout = () => {
    // @ts-ignore
    window.location = `${baseUrl}/auth/logout`;
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const user = useSelector(getAccountUser);

  return (
    <div>
      <button onClick={handleClick}>
        <AccountIcon />
      </button>
      <Menu
        PaperProps={{
          style: {
            minWidth: 200,
          },
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={true}>{user?.email ? user.email : ""}</MenuItem>
        <MenuItem onClick={handleLogout}>Sign Out</MenuItem>
      </Menu>
    </div>
  );
};

export default AccountMenu;
