import moment from "moment";

import { Person } from "../Person/types";
import { CreateCustomerApi, CustomerApi } from "../API/types";
import { undefinedToNull } from "../utils";
import getBase64 from "../../utils/fileToBase64";

export const entityToCustomerApi = async (entity: Person): Promise<CustomerApi> => {
  const idDocumentFile = await getBase64(entity.idDocumentFile);
  const payslipFile = await getBase64(entity.payslipFile);
  return {
    uuid: entity.uuid,
    citizenship: undefinedToNull(entity.citizenship),
    cityAddress: undefinedToNull(entity.cityAddress),
    cityOfBirth: entity.city ? entity.city : "",
    countryAddress: undefinedToNull(entity.countryAddress),
    dateOfBirth: moment(entity.dob).format("YYYY-MM-DD"),
    email: undefinedToNull(entity.email),
    employer: undefinedToNull(entity.employer),
    firstName: entity.firstName ? entity.firstName : "",
    idDocumentFile: idDocumentFile ? idDocumentFile : "",
    idDocumentFileName: entity.idDocumentFileName ? entity.idDocumentFileName : "",
    idDocumentNumber: entity.idDocumentNumber ? entity.idDocumentNumber : "",
    idDocumentType: entity.idDocumentType ? entity.idDocumentType : "",
    irdNumber1: undefinedToNull(entity.IRDNumber),
    irdNumber2: undefinedToNull(entity.samoanIRDNumber),
    lastName: entity.lastName ? entity.lastName : "",
    mobilePhone: undefinedToNull(entity.mobile),
    payslipFile: payslipFile ? payslipFile : "",
    payslipFileName: entity.payslipFileName ? entity.payslipFileName : "",
    postalCodeAddress: undefinedToNull(entity.postalCodeAddress),
    streetAddress: undefinedToNull(entity.streetAddress),
  };
};

export const entityToCreateCustomerApi = (entity: Person): CreateCustomerApi => {
  return {
    uuid: entity.uuid,
    citizenship: undefinedToNull(entity.citizenship),
    cityAddress: undefinedToNull(entity.cityAddress),
    cityOfBirth: entity.city ? entity.city : "",
    countryAddress: undefinedToNull(entity.countryAddress),
    dateOfBirth: moment(entity.dob).format("YYYY-MM-DD"),
    email: undefinedToNull(entity.email),
    employer: undefinedToNull(entity.employer),
    firstName: entity.firstName ? entity.firstName : "",
    idDocumentFile: entity.idDocumentFile ? entity.idDocumentFile : "",
    idDocumentFileName: entity.idDocumentFileName ? entity.idDocumentFileName : "",
    idDocumentNumber: entity.idDocumentNumber ? entity.idDocumentNumber : "",
    idDocumentType: entity.idDocumentType ? entity.idDocumentType : "",
    payslipFile: entity.payslipFile ? entity.payslipFile : "",
    payslipFileName: entity.payslipFileName ? entity.payslipFileName : "",
    irdNumber1: undefinedToNull(entity.IRDNumber),
    irdNumber2: undefinedToNull(entity.samoanIRDNumber),
    lastName: entity.lastName ? entity.lastName : "",
    mobilePhone: undefinedToNull(entity.mobile),
    postalCodeAddress: undefinedToNull("00-0000"),
    streetAddress: undefinedToNull(entity.streetAddress),
  };
};
