import React from "react";

import Step from "./Step";

const Steps = () => {
  return (
    <div className="border-r">
      <div className="font-bold ml-4 h-10 flex items-center">Steps</div>
      <Step text={"Sender Details"} stepName="senderDetails" />
      <Step
        text={"Receiver Details"}
        stepName="receiverDetails"
      />
      <Step
        text={"Transfer Details"}
        stepName="transferDetails"
      />
    </div>
  );
};

export default Steps;
