import React from "react";

import FieldInput from "./FieldInput";
import { FormikValues } from "formik";

interface Props {
  handleChange: (event: React.FormEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FormEvent<HTMLInputElement>) => void;
  values: FormikValues;
  errors: any;
  touched: any;
  disabled?: boolean;
}

const PersonalDetails = ({ handleChange, handleBlur, values, errors, touched, disabled = false }: Props) => {
  return (
    <>
      <div className="flex flex-row">
        <FieldInput
          disabled={disabled}
          name={"firstName"}
          title={"First name*"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
          classes={"w-56 mr-8"}
        />
        <FieldInput
          name={"middleName"}
          title={"Middle name"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
          classes={"w-56 mr-8"}
        />
      </div>
      <div className="flex flex-row">
        <FieldInput
          disabled={disabled}
          name={"lastName"}
          title={"Last name*"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
          classes={"w-56 mr-8"}
        />
        <FieldInput
          name={"otherNames"}
          title={"Other known names"}
          handleChange={handleChange}
          handleBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
          classes={"w-64"}
        />
      </div>
    </>
  );
};

export default PersonalDetails;
