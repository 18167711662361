import React from "react";
import { Api } from "../API/API";
import { Nullable } from "../../types";
import { useAppDispatch } from "../../hooks";
import { updateAccountUser, updateIsLoadingAccountUser } from "./accountSlice";
import { SalesforceAccountUser } from "../API/types";
import { AccountUser } from "./types";
import { ADBTestBank, Frankie, NationalBankOfSamoa, SamoaCommercialBank } from "../constants";
import { useIsLocalEnv } from "../../GlobalHooks";

const domainToBankMap = (domain: string) => {
  switch (domain) {
    case "nbsamoa.com":
      return NationalBankOfSamoa;
    case "scbl.ws":
      return SamoaCommercialBank;
    case "frankie.ws":
      return Frankie;
    default:
      return ADBTestBank;
  }
};

const mapEmailToBank = (email: string) => {
  const domain = email.split("@").pop();
  if (!domain) {
    return null;
  }
  return domainToBankMap(domain);
};

const shapeUser = (salesforceAccountUser: SalesforceAccountUser) => {
  const { username, email, first_name, last_name, display_name } = salesforceAccountUser;
  const accountUser: AccountUser = {
    userName: username,
    displayName: display_name,
    email: email,
    firstName: first_name,
    lastName: last_name,
    bankName: mapEmailToBank(email),
  };
  return accountUser;
};

export const useGetUser = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const dispatch = useAppDispatch();
  const getUser = async () => {
    try {
      if (useIsLocalEnv()) {
        dispatch(
          updateAccountUser({
            accountUser: {
              userName: "username",
              displayName: "test display name",
              email: "testemail@emailprovider.com",
              firstName: "testfirstname",
              lastName: "testlastname",
              bankName: "testbankname",
            },
          }),
        );
        return;
      }
      setIsLoading(true);
      dispatch(updateIsLoadingAccountUser({ isLoading: true }));
      const user = await Api.getUser();
      dispatch(updateAccountUser({ accountUser: shapeUser(user) }));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }

    setIsLoading(false);
  };
  return {
    isLoading,
    getUser: React.useCallback(() => {
      return getUser();
    }, [dispatch]),
  };
};
