import React from "react";
import classNames from "classnames";
import LoadingSpinner from "../../utils/LoadingSpinner";

interface Props {
  children: JSX.Element[] | JSX.Element;
  onClick?: () => void;
  theme?: "primary" | "secondary";
  className?: string;
  type?: "submit" | "button";
  disabled?: boolean;
  disablePadding?: boolean;
  loading?: boolean;
}

const CustomButton = ({
  children,
  onClick,
  theme = "primary",
  className,
  type = "button",
  disabled = false,
  disablePadding = false,
  loading = false,
}: Props) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={classNames(
        "group h-8 flex items-center text-white rounded justify-center",
        {
          "bg-pacificBlue hover:bg-pacificBlueDeep": theme === "primary" && !loading,
          "bg-stone": (theme === "secondary" && !loading) || disabled,
          "hover:bg-jumbo": theme === "secondary" && !disabled,
          "p-4": !disablePadding,
          "pr-0": loading,
        },
        className,
      )}
    >
      {children}
      {loading ? (
        <div className={classNames("ml-3 flex items-center", { invisible: !loading })}>
          <LoadingSpinner />
        </div>
      ) : null}
    </button>
  );
};

export default CustomButton;
