import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import relationshipSeedData from "./relationshipSeedData.json";
import { NewRelationshipPage, Relationship, RelationshipStore } from "./types";
import { Nullable } from "../../types";

const relationshipData: any = relationshipSeedData;

const initialState: RelationshipStore = {
  activeRelationshipUuid: null,
  isFetching: true,
  relationships: {},
};

const relationshipSlice = createSlice({
  name: "relationshipStore",
  initialState,
  reducers: {
    updateActiveStep: (state, action: PayloadAction<{ relationshipUuid: string; activeStep: NewRelationshipPage }>) => {
      state.relationships = {
        ...state.relationships,
        [action.payload.relationshipUuid]: {
          ...state.relationships[action.payload.relationshipUuid],
          relationshipUuid: action.payload.relationshipUuid,
          activePage: action.payload.activeStep,
        },
      };
    },
    createRelationshipRequest: (state, action: PayloadAction<Relationship>) => {
      state.activeRelationshipUuid = action.payload.relationshipUuid;
      state.isFetching = true;
      state.relationships = {
        ...state.relationships,
        [action.payload.relationshipUuid]: {
          ...state.relationships[action.payload.relationshipUuid],
          createdAt: moment().valueOf(),
          relationshipUuid: action.payload.relationshipUuid,
          receiverUuid: action.payload.receiverUuid,
          senderUuid: action.payload.senderUuid,
          status: action.payload.status,
          activePage: "senderDetails",
          amount: action.payload.amount,
          currencyCode: action.payload.currencyCode,
          frequency: action.payload.frequency,
          reason: action.payload.reason,
        },
      };
    },
    addRelationships: (state, action: PayloadAction<{ [uuid: string]: Relationship }>) => {
      state.relationships = {
        ...state.relationships,
        ...action.payload,
      };
    },
    updateRelationshipRequest: (state, action: PayloadAction<Relationship>) => {
      state.isFetching = true;
      state.relationships = {
        ...state.relationships,
        [action.payload.relationshipUuid]: {
          ...state.relationships[action.payload.relationshipUuid],
          ...action.payload,
        },
      };
    },
    updateActiveRelationship: (state, action: PayloadAction<Nullable<string>>) => {
      state.activeRelationshipUuid = action.payload;
    },
  },
});

export const {
  createRelationshipRequest,
  updateRelationshipRequest,
  updateActiveRelationship,
  updateActiveStep,
  addRelationships,
} = relationshipSlice.actions;

export default relationshipSlice.reducer;
