import React from "react";
import { useFilteredPersons } from "../Person/personHooks";
import { Nullable } from "../../types";
import EntityRow from "./EntityRow";
import { useGetCustomers } from "./EntityHooks";
import LoadingSpinner from "../../utils/LoadingSpinner";
import classNames from "classnames";
import { Person } from "../Person/types";

interface Props {
  searchValue: Nullable<string>;
}

const EntityTable = ({ searchValue }: Props) => {
  const [checkedPersonUuid, setCheckedPersonUuid] = React.useState<Nullable<string>>(null);
  const [persons, setPersons] = React.useState<{ [uuid: string]: Person }>({});

  const getFilteredPersons = useFilteredPersons();

  const { loading } = useGetCustomers();

  React.useEffect(() => {
    const persons = getFilteredPersons(searchValue);
    setPersons(persons);
  }, [searchValue, loading]);

  return (
    <div className="overflow-auto">
      <table className="text-xs w-full">
        <thead>
          <tr className="bg-cloud hover:bg-cotton h-8 border-b text-slate sticky top-0 z-10 text-slate hover:text-coal">
            <th className="text-left pl-4 w-4">
              <div className={classNames("pl-2 flex items-center", { invisible: !loading })}>
                <LoadingSpinner />
              </div>
            </th>
            <th className="text-left pl-4">
              <div className="flex">
                <div>Name</div>
              </div>
            </th>
            <th className="text-left pl-4">Date of birth</th>
          </tr>
        </thead>
        <tbody className="">
          {Object.keys(persons).map((personUuid: string) => {
            return (
              <EntityRow
                key={personUuid}
                person={persons[personUuid]}
                setChecked={() => setCheckedPersonUuid(personUuid)}
                checkedPersonUuid={checkedPersonUuid}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EntityTable;
