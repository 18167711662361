import { combineReducers } from "redux";
import relationshipSlice from "./domains/Relationship/relationshipSlice";
import personSlice from "./domains/Person/personSlice";
import accountSlice from "./domains/Account/accountSlice";

const rootReducer = combineReducers({
  relationshipStore: relationshipSlice,
  personStore: personSlice,
  accountStore: accountSlice,
});

export type AppStore = ReturnType<typeof rootReducer>;

export default rootReducer;
