import React from "react";
import { RouteComponentProps } from "@reach/router";

interface Props extends RouteComponentProps {
  children: JSX.Element[] | JSX.Element;
  backPath?: string;
}

const Entities: React.FC<Props> = ({ children }: Props) => {
  return (
      <div className="flex flex-1">
        {children &&
        React.Children.map(children, (child: any) =>
            React.cloneElement(child, {
              className: "flex flex-1",
            }),
        )}
      </div>
  );
};

export default Entities;
